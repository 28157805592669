exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cultivation-tsx": () => import("./../../../src/pages/cultivation.tsx" /* webpackChunkName: "component---src-pages-cultivation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-recipes-1-tsx": () => import("./../../../src/pages/recipes/1.tsx" /* webpackChunkName: "component---src-pages-recipes-1-tsx" */),
  "component---src-pages-recipes-2-tsx": () => import("./../../../src/pages/recipes/2.tsx" /* webpackChunkName: "component---src-pages-recipes-2-tsx" */),
  "component---src-pages-recipes-3-tsx": () => import("./../../../src/pages/recipes/3.tsx" /* webpackChunkName: "component---src-pages-recipes-3-tsx" */),
  "component---src-pages-recipes-4-tsx": () => import("./../../../src/pages/recipes/4.tsx" /* webpackChunkName: "component---src-pages-recipes-4-tsx" */),
  "component---src-pages-recipes-5-tsx": () => import("./../../../src/pages/recipes/5.tsx" /* webpackChunkName: "component---src-pages-recipes-5-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-rice-varieties-tsx": () => import("./../../../src/pages/rice-varieties.tsx" /* webpackChunkName: "component---src-pages-rice-varieties-tsx" */),
  "component---src-templates-using-dsg-tsx": () => import("./../../../src/templates/using-dsg.tsx" /* webpackChunkName: "component---src-templates-using-dsg-tsx" */)
}

